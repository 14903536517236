import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/ueECPnfCyR8">
    <SEO title="Defining Israel - Hebrews" />
  </Layout>
)
export default SermonPost
